// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-asianetwork-page-js": () => import("./../../../src/templates/asianetwork-page.js" /* webpackChunkName: "component---src-templates-asianetwork-page-js" */),
  "component---src-templates-authors-page-js": () => import("./../../../src/templates/authors-page.js" /* webpackChunkName: "component---src-templates-authors-page-js" */),
  "component---src-templates-cinemasofasia-page-js": () => import("./../../../src/templates/cinemasofasia-page.js" /* webpackChunkName: "component---src-templates-cinemasofasia-page-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-filmminutes-page-js": () => import("./../../../src/templates/filmminutes-page.js" /* webpackChunkName: "component---src-templates-filmminutes-page-js" */),
  "component---src-templates-historiesofstem-page-js": () => import("./../../../src/templates/historiesofstem-page.js" /* webpackChunkName: "component---src-templates-historiesofstem-page-js" */),
  "component---src-templates-impact-page-js": () => import("./../../../src/templates/impact-page.js" /* webpackChunkName: "component---src-templates-impact-page-js" */),
  "component---src-templates-join-page-js": () => import("./../../../src/templates/join-page.js" /* webpackChunkName: "component---src-templates-join-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-peer-review-page-js": () => import("./../../../src/templates/peerReview-page.js" /* webpackChunkName: "component---src-templates-peer-review-page-js" */),
  "component---src-templates-reeditions-page-js": () => import("./../../../src/templates/reeditions-page.js" /* webpackChunkName: "component---src-templates-reeditions-page-js" */),
  "component---src-templates-series-page-js": () => import("./../../../src/templates/series-page.js" /* webpackChunkName: "component---src-templates-series-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-videographicbooks-page-js": () => import("./../../../src/templates/videographicbooks-page.js" /* webpackChunkName: "component---src-templates-videographicbooks-page-js" */)
}

